body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(218, 218, 218);
  background: linear-gradient(
    180deg,
    rgba(218, 218, 218, 1) 11%,
    rgba(205, 205, 205, 1) 49%,
    rgba(230, 230, 230, 1) 91%
  );
}
html,
body,
#root,
.App {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#root::-webkit-scrollbar-track {
  display: none !important;
}

#root::-webkit-scrollbar-thumb {
  display: none !important;
}

#root::-webkit-scrollbar {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#photoViewer {
  width: 100%;
}

#renderCanvas {
  filter: drop-shadow(16px 16px 20px gray);
}
