/* ----- CORE ----- */

@font-face {
  font-family: 'Wigrum';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Wigrum-Medium.woff);
}

@font-face {
  font-family: 'Wigrum';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Wigrum-Regular.woff);
}

html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

a:link,
a:visited {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  text-decoration: none;
  pointer-events: auto;
}

a:hover,
a:active {
  color: #09b6b6;
}

.hidden {
  display: none;
}

/* ----- LAYOUT ----- */

.top {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  pointer-events: none;
}

.middle {
  width: 100%;
  height: 50%;
  position: fixed;
  top: 25%;
  left: 0;
  z-index: 1;
  text-align: center;
  pointer-events: none;
}

.bottom {
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 30px; /* added for task bar */
  left: 0;
  z-index: 1;
  text-align: center;
  pointer-events: none;
}

.left {
  float: left;
  width: 25%;
  height: 100px;
  text-align: left;
  padding: 10px 10px;
  /*border: 4px dotted blue;*/
}

.leftfull {
  float: left;
  width: 25%;
  height: 100%;
  text-align: left;
  padding: 10px 10px;
  /*border: 4px dotted blue;*/
}

.center {
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 100px;
  padding: 10px 10px;
  /*border: 4px dotted blue;*/
}

.centerfull {
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 100%;
  padding: 10px 10px;
  /*border: 4px dotted blue;*/
}

.right {
  float: right;
  width: 25%;
  height: 100px;
  text-align: right;
  padding: 10px 10px;
  /*border: 4px dotted blue;*/
}

.rightfull {
  float: right;
  width: 25%;
  height: 100%;
  text-align: right;
  padding: 10px 10px;
  /*border: 4px dotted blue;*/
}

/* ----- CANVAS ----- */

#renderCanvas {
  position: fixed;
  width: 100%;
  height: 100%;
  touch-action: none;
}

#renderCanvas:focus {
  outline: none;
  box-shadow: none;
}

/* ----- TEXT ----- */

.heading {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 28px;
  color: black;
}

.headingblue {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 28px;
  color: #084a64;
}

.subheading {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 24px;
  color: black;
}

.instruction {
  font-family: Wigrum;
  font-weight: 400;
  font-size: 16px;
  color: white;
}

/* ----- BACK LINK ----- */

.backlink {
  display: block;
  background: transparent url('assets/back_arrow@2x.png') left center no-repeat;
  background-size: 15px 25px;
  text-align: left;
}

.backlink:hover {
  background-image: url('assets/back_arrow_green@2x.png');
}

/* ----- DRAWER ----- */

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden; /* Disable horizontal scroll */
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .togglebtn {
  position: absolute;
  top: 20px;
  right: 15px;
  border: none;
  width: 22px;
  height: 18px;
  background: transparent url('assets/icon_drawer_out@2x.png') center center no-repeat;
  background-size: 22px 18px;
}

.sidenav .togglebtn:focus {
  outline: none;
  box-shadow: none;
}

.sidenavcontent {
  padding: 7px 7px;
}

/* ----- CATALOG ----- */

.catalogbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;

  font-family: Wigrum;
  font-weight: 500;
  font-size: 18px;
  color: black;
  background-color: transparent;

  cursor: pointer;
  pointer-events: auto;
  transition: transform 0.2s;
}

.catalogbtn:hover {
  border: none;
  transform: scale(1.1);
}

.catalogbtn:focus {
  outline: none;
  box-shadow: none;
}

/* ----- SIGNOFF ----- */

.signoffarea {
  position: fixed;
  bottom: 0px;
  padding: 20px 20px;
  width: 250px;
  height: 250px;
}

.signoffroomate {
  display: inline-block;
  width: 250px;
  height: 110px;
  background: #ffffff;
  border: 1px solid #e5e7e5;
  border-radius: 6px;
  margin-bottom: 20px;
}

.signoffroomateicon {
  display: inline-block;
  width: 50px;
  height: 110px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #e5e7e5 url('assets/roommate_icon@2x.png') center center no-repeat;
  background-size: 30px 55px;
}

.signoffroomateiconchecked {
  display: inline-block;
  width: 50px;
  height: 110px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #09b6b6 url('assets/icon_roommate_check@2x.png') center center no-repeat;
  background-size: 19px 14px;
}

.signoffroomatetext {
  display: inline-block;
  width: 150px;
  height: 90px;
  margin-left: 75px;
  margin-top: 10px;
}

.signoffroomatebutton {
  display: inline-block;
  width: 130px;
  height: 35px;
  background-color: #09b6b6;
  border: none;
  border-radius: 4px;
  margin-top: 5px;
}

.signoffroomatebutton:hover {
  transform: scale(1.1);
}

.signoffroomatebutton:focus {
  outline: none;
  box-shadow: none;
}

.signoffroomatebuttontext {
  font-family: Wigrum;
  font-weight: 400;
  font-size: 12px;
  color: white;
}

.signofftextname {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 16px;
  color: #113649;
}

.signofftextinfo {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 12px;
  color: #113649;
}

.signofftextsignoff {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 8px;
  color: #113649;
}

/* ----- MEASURE ----- */

#measureToolIndicator {
  position: absolute;
  color: white;
  background-color: black;
  z-index: 10;
  pointer-events: none;
}

.measuretools {
  display: inline-block;
  top: 0;
  left: 0;
  width: 25%;
  height: 25%;
  padding: 10px 10px;
  border: 4px dotted blue;
}

/* ----- BUTTONS (IMAGES) ----- */

.btn-measure {
  width: 54px;
  height: 54px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/icon_measure@2x.png') center center no-repeat;
  background-size: 54px 54px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-measure:hover {
  background-image: url('assets/icon_measure_on@2x.png');
}

.btn-measure:focus {
  outline: none;
  box-shadow: none;
}

.btn-zoomin {
  width: 54px;
  height: 54px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/icon_zoom_in@2x.png') center center no-repeat;
  background-size: 54px 54px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-zoomin:hover {
  background-image: url('assets/icon_zoom_in_on@2x.png');
}

.btn-zoomin:focus {
  outline: none;
  box-shadow: none;
}

.btn-zoomout {
  width: 54px;
  height: 54px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/icon_zoom_out@2x.png') center center no-repeat;
  background-size: 54px 54px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-zoomout:hover {
  background-image: url('assets/icon_zoom_out_on@2x.png');
}

.btn-zoomout:focus {
  outline: none;
  box-shadow: none;
}

.btn-switch2d {
  width: 54px;
  height: 54px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/icon_2d@2x.png') center center no-repeat;
  background-size: 54px 54px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-switch2d:hover {
  background-image: url('assets/icon_2d_on@2x.png');
}

.btn-switch2d:focus {
  outline: none;
  box-shadow: none;
}

.btn-switch3d {
  width: 54px;
  height: 54px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/icon_3d@2x.png') center center no-repeat;
  background-size: 54px 54px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-switch3d:hover {
  background-image: url('assets/icon_3d_on@2x.png');
}

.btn-switch3d:focus {
  outline: none;
  box-shadow: none;
}

.btn-view {
  width: 54px;
  height: 54px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/icon_view@2x.png') center center no-repeat;
  background-size: 54px 54px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-view:hover {
  background-image: url('assets/icon_view_on@2x.png');
}

.btn-view:focus {
  outline: none;
  box-shadow: none;
}

.btn-clearmeasurement {
  width: 222px;
  height: 50px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/btn_clearMeasurement@2x.png') center center no-repeat;
  background-size: 222px 50px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-clearmeasurement:hover {
  background-image: url('assets/btn_clearMeasurement_on@2x.png');
}

.btn-clearmeasurement:focus {
  outline: none;
  box-shadow: none;
}

.btn-info {
  width: 132px;
  height: 50px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/btn_info@2x.png') center center no-repeat;
  background-size: 132px 50px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-info:hover {
  background-image: url('assets/btn_info_on@2x.png');
}

.btn-info:focus {
  outline: none;
  box-shadow: none;
}

.btn-delete {
  width: 132px;
  height: 50px;
  margin: 0px 0px;
  padding: 10px 10px;
  border: none;
  background: transparent url('assets/btn_delete@2x.png') center center no-repeat;
  background-size: 132px 50px;

  cursor: pointer;
  pointer-events: auto;
}

.btn-delete:hover {
  background-image: url('assets/btn_delete_on@2x.png');
}

.btn-delete:focus {
  outline: none;
  box-shadow: none;
}

.btn-delete:disabled,
.btn-delete[disabled] {
  background-image: url('assets/btn_delete_disabled@2x.png');
}

/* ----- GENERIC BUTTON (FONT AWESOME) ----- */

.btn {
  background-color: transparent;
  border: none;
  color: #084a64; /*#41b5c2;*/
  margin: 0px 0px;
  padding: 8px 8px;
  font-size: 14px;
  cursor: pointer;
  pointer-events: auto;
}

.btn:hover {
  color: #0d739c;
  border: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn:disabled,
.btn[disabled] {
  color: #666666;
}

/* ----- MODAL ----- */

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0, 0); /* Fallback color */
  /*background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px 20px;
  border-radius: 8px 8px;
  box-shadow: 0 2px 14px 0 #5e617388;
  width: 250px; /* Could be more or less, depending on screen size */
  height: 250px;
}

.modal-content-purchase {
  background-color: #ffffff;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px 20px;
  border-radius: 8px 8px;
  box-shadow: 0 2px 14px 0 #5e617388;
  width: 750px; /* Could be more or less, depending on screen size */
  height: 650px;
}

.modal-title {
  font-family: Wigrum;
  font-weight: 600;
  font-size: 16px;
  color: #113649;
}

.modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Option */
.modal-option {
  display: inline-block;
  width: 250px;

  background: #ffffff;
  border: 1px solid #e5e7e5;
  border-radius: 6px;
  margin-bottom: 10px;
}

.modal-option-icon {
  display: inline-block;
  width: 40px;

  background: transparent url('assets/icon_check_on@2x.png') center center no-repeat;
  background-size: 16px 16px;
}

.modal-option-text {
  display: inline-block;
  width: 175px;

  margin-left: 35px;

  padding: 10px 0px;
}

.modal-option-text-name {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 16px;
  color: #113649;
  cursor: default;
}

.modal-option-text-info {
  font-family: Wigrum;
  font-weight: 500;
  font-size: 12px;
  color: #113649;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border: none;

  font-family: Wigrum;
  font-weight: 500;
  font-size: 18px;
  color: black;
  background-color: transparent;

  cursor: pointer;
  pointer-events: auto;
  transition: transform 0.2s;
}

.custom-file-upload:hover {
  border: none;
  transform: scale(1.1);
}

.custom-file-upload:focus {
  outline: none;
  box-shadow: none;
}

input[type='file'] {
  display: none;
}
